// third-party
import { Dayjs } from 'dayjs'

// project imports
import { Company } from './Company'
import { Branch } from './Branch'
import { Assistant } from './Assistant'
import { Service } from './Service'
import { Pageable, Searchable } from './Page'
import { Client } from './Client'

// ========================|| TYPES - BOOKING ||======================== //

export enum BookingStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
}

export enum DepositStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  IN_PROCESS = 'IN_PROCESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  IN_MEDIATION = 'IN_MEDIATION',
  CHARGED_BACK = 'CHARGED_BACK',
}

export interface Booking {
  id: string
  date: Date
  duration: number
  price: number | null
  currency: string | null
  status: BookingStatus
  depositAmount: number
  depositStatus: DepositStatus
  checkoutUrl: string | null
  refundDeadline: Date | null
  company: Company
  branch: Branch
  assistant: Assistant
  service: Service
  client: Client
  createdAt: Date
}

export interface BookingParams extends Pageable, Searchable {
  startDate?: Dayjs
  endDate?: Dayjs
  status: BookingStatus[]
  assistants: Assistant[]
  branchId?: string
}

export interface BookingCreateForm {
  service: Service
  assistant: Assistant
  client: Client
  date: Dayjs
  time: string
}