import React, { useContext } from 'react'

// material-ui
import { Autocomplete, Box, FormControl, Grid2 as Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// third-party
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// project imports
import CompanyContext from '../../../../../context/company/CompanyContext'
import useBranches from '../../../../../hooks/branch/useBranches'

// ========================|| BOOKING FORM - SELECT BRANCH ||======================== //

export default function SelectBranchStep() {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t } = useTranslation()
  const { company } = useContext(CompanyContext)
  const { control, resetField } = useFormContext()
  const { loading, branches } = useBranches(company.id)

  return (
    <Controller
      name='branch'
      control={control}
      rules={{ required: 'This field is required.' }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
          <Autocomplete
            options={branches}
            value={value || null}
            loading={loading}
            loadingText={t('Loading...')}
            onChange={(e, branch) => {
              resetField('assistant')
              resetField('date')
              resetField('time')
              return onChange(branch)
            }}
            disableClearable
            isOptionEqualToValue={(option, value) => (value ? option.id === (value?.id || value) : false)}
            getOptionLabel={branch => branch.name}
            renderInput={params =>
              matchesXS ? (
                <TextField {...params} placeholder={t('Branch')} error={!!error} helperText={error ? t(error.message as string) : null} />
              ) : (
                <TextField {...params} label={t('Branch')} error={!!error} helperText={error ? t(error.message as string) : null} />
              )
            }
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems='center'>
                    <Grid sx={{ display: 'flex', width: 44 }}>
                      <LocationOnIcon sx={{ color: 'text.secondary' }} />
                    </Grid>
                    <Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      <Box component='span' sx={{ fontWeight: 'bold' }}>
                        {option.name}
                      </Box>
                      <Typography variant='body2' color='text.secondary'>
                        {option.address.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )
            }}
          />
        </FormControl>
      )}
    />
  )
}
