// material-ui
import { FormControl, Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material'
import { DayCalendarSkeleton, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// third-party
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Dayjs } from 'dayjs'

// project imports
import useDates from '../../../../../hooks/availability/useDates'
import TimeAutocomplete from './TimeAutocomplete'
import { locale } from '../../../../../utils/locale'
import { Branch } from '../../../../../types/Branch'
import { Service } from '../../../../../types/Service'
import { Assistant } from '../../../../../types/Assistant'

// ========================|| BOOKING FORM - SELECT DATE ||======================== //

export default function SelectDateStep() {
  // hooks
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.only('xs'))
  const { t, i18n } = useTranslation()
  const { control, watch, setValue } = useFormContext()

  // state
  const branch: Branch = watch('branch')
  const service: Service = watch('service')
  const assistant: Assistant = watch('assistant')
  const date: Dayjs = watch('date')

  const { loading, dates } = useDates(service.id, assistant.id)

  const shouldDisableDate = (dayjs: Dayjs) => {
    return !dates.find(date => dayjs.isSame(date, 'date')) && !dayjs.isSame(date)
  }

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 6 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale(i18n.language)}>
          <Controller
            name='date'
            control={control}
            rules={{ required: 'This field is required.' }}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth>
                <MobileDatePicker
                  value={value || null}
                  onChange={value => {
                    setValue('time', null, { shouldDirty: true })
                    onChange(value)
                  }}
                  loading={loading}
                  shouldDisableDate={shouldDisableDate}
                  renderLoading={() => <DayCalendarSkeleton />}
                  disablePast
                  closeOnSelect
                  format='dddd D, MMMM'
                  views={['day']}
                  label={matchesXS ? null : t('Date')}
                  slotProps={{
                    toolbar: { hidden: true },
                    textField: {
                      placeholder: matchesXS ? t('Date') : '',
                      inputProps: {
                        style: {
                          textTransform: 'capitalize'
                        }
                      }
                    }
                  }}
                  timezone={branch.address.timezone}
                  disableHighlightToday
                />
              </FormControl>
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <TimeAutocomplete />
      </Grid>
    </Grid>
  )
}
