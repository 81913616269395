// material-ui
import { Divider, Grid2 as Grid } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import MainCard from '../../../../ui-components/extended/card/MainCard'
import MainContent from '../../../../ui-components/extended/table/MainContent'
import ClientTable from '../ClientTable/ClientTable'
import ClientSearchBar from '../ClientSearchBar/ClientSearchBar'
import ClientCreateButton from '../ClientCreateButton/ClientCreateButton'

// ========================|| CLIENT - CARD ||======================== //

export default function ClientCard() {
  const { t } = useTranslation()

  return (
    <MainCard title={t('Clients')} content={false} border={false}>
      <MainContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid size='grow'>
            <ClientSearchBar />
          </Grid>
          <Grid>
            <ClientCreateButton />
          </Grid>
        </Grid>
      </MainContent>
      <Divider />
      <ClientTable />
    </MainCard>
  )
}