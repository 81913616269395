import React, { useContext, useState } from 'react'

// material-ui
import { Autocomplete, Box, Grid2 as Grid, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// third-party
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import SubscriptionApi from '../../../../api/subscription/SubscriptionApi'
import useAuth from '../../../../context/auth/useAuth'
import SubscriptionPricingCard from '../SubscriptionPricingCard/SubscriptionPricingCard'
import { EXCEPTION_SUBSCRIPTION_DOWNGRADE } from '../../../../api/exceptions/exceptions'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { Subscription } from '../../../../types/Subscription'
import { ApiError } from '../../../../types/ApiError'

// apis
const subscriptionApi = new SubscriptionApi()

// ========================|| SUBSCRIPTION - UPDATE FORM ||======================== //

interface FormValues {
  assistants: string
}

const numberOfAssistants = Array.from({ length: 50 }, (_, i) => i + 1).map(number => number.toString())

export default function SubscriptionUpdateForm() {
  // hooks
  const { t } = useTranslation()
  const { auth } = useAuth()
  const { setSubscription } = useContext(OptionalSubscriptionContext)
  const { subscription } = useContext(SubscriptionContext)
  const dispatch = useDispatch()

  // state
  const [loading, setLoading] = useState(false)

  // react-hook-form
  const { control, watch, formState, handleSubmit, reset, setError } = useForm<FormValues>({
    defaultValues: {
      assistants: subscription.assistants.toString(),
    },
  })

  const assistants = parseInt(watch('assistants'))

  const handleSuccess = (subscription: Subscription) => {
    setSubscription(subscription)

    reset({ assistants: subscription.assistants.toString() })

    dispatch(
      setSnackbar({
        message: t('Subscription updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.status === 409 && error.message === EXCEPTION_SUBSCRIPTION_DOWNGRADE) {
      setError('assistants', { message: EXCEPTION_SUBSCRIPTION_DOWNGRADE })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating subscription'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleCloseForm = () => {
    setLoading(false)
  }

  const handleSubmitForm: SubmitHandler<FormValues> = form => {
    auth!.getIdToken().then(token => {
      setLoading(true)
      subscriptionApi
        .update(token, subscription.id, {
          assistants: parseInt(form.assistants),
        })
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleCloseForm)
    })
  }

  return (
    <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid size={12}>
          <Controller
            name='assistants'
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={numberOfAssistants}
                value={value}
                fullWidth
                disableClearable
                onChange={(e, numberOfAssistants) => onChange(numberOfAssistants)}
                getOptionLabel={value => value.toString()}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('Number of assistants') as string}
                    error={!!error}
                    helperText={error ? t(error.message as string) : null}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <SubscriptionPricingCard plan={subscription.plan} assistants={assistants} />
        </Grid>
        <Grid size={12}>
          <LoadingButton type='submit' variant='contained' disabled={!formState.isDirty} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
